import Vue from 'vue'
import ElementUI from 'element-ui';
import './theme/element/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import materialDesignIcons from './assets/fonts/css/materialdesignicons.css';
import 'default-passive-events';
Vue.config.productionTip = false;
Vue.prototype.Mock = require('mockjs');
Vue.use(ElementUI);
Vue.use(materialDesignIcons);
// router.afterEach(() => {
//     window.scrollTo(0, 0)
// })
new Vue({
    router,
    store,
    data() {
        return {
            layoutMode: 'top'
        }
    },
    render: h => h(App)
}).$mount('#app')
