<template>
    <div id="app">
        <el-container
            class="main-container"
            v-if="$route.path.indexOf('login') < 0">
            <el-backtop
                target=".el-main"
                :bottom="topBtn.bottom"
                :right="topBtn.right"
                :visibility-height="$store.getters.getDevice === 'mobile' ? 1000 : 2400">
                <div class="custom-to-top">
                <img class="icon" src="./assets/images/to-top.webp" />
                <img class="border" src="./assets/images/to-top-border.webp" />
                </div>
            </el-backtop>
            <div class="qrcode" :class="{ hide: hideQrCode }">
                <img src="./assets/images/qrcode.webp" />
                <p>扫码进入</p>
                <p>华睿生物公众号</p>
            </div>
        <el-drawer
            :visible.sync="drawerVisible"
            :with-header="false"
            :modal="false"
            :custom-class="'menu-drawer'">
            <div class="drawer-header">
                <el-button type="text" @click="drawerVisible = false">
                    <i class="el-icon-close"></i>
                </el-button>
            </div>
            <div class="menu-wrapper" :class="[$store.getters.getLanguage === '' ? '' : 'EN']">
                <div
                    v-for="item in menuList"
                    :key="item.index"
                    class="menu-item"
                    :class="{ active: item.index === $route.path }"
                    @click="handleRouteChange(item.index)">
                    {{ $store.getters.getLanguage === '' ? item.name : item.name_EN}}
                </div>
            </div>
        </el-drawer>
        <el-header v-if="LAYOUTMODE.indexOf('top') >= 0">
            <div class="flex-wrapper">
                <img
                class="logo"
                :src="require(`./assets/images/logo${$store.getters.getLanguage}.webp`)"
                @click="handleIndex()"/>
                <div class="header-func-wrapper" :style="{ display: 'flex', 'align-items': 'center' }">
                    <el-button class="menu-btn" type="text" @click="drawerVisible = true">
                        <i class="iconfont icon-menu"></i>
                    </el-button>
                    <el-radio-group
                        class="language-btn"
                        v-model="currentLanguage"
                        @input="handleLanguage()">
                        <el-radio-button
                            v-for="btn in languageList"
                            :key="btn"
                            :label="btn">
                        </el-radio-button>
                    </el-radio-group>
                </div>
            </div>
        </el-header>
        <el-container
            class="main-panel"
            :class="{ 'no-footer': LAYOUTMODE.indexOf('footer') < 0 }">
            <el-main>
                <div id="archor" ref="archor"></div>
                <router-view />
                <img :src="footer" :style="{ 'width': '100%', 'margin-top': '-0.1rem' }"/>
                <div class="footer-bar" v-if="currentLanguage === '中文'">
                    浙江华睿生物技术有限公司&nbsp;保留所有权利&nbsp;
                    <a href="https://beian.miit.gov.cn/">浙ICP备20000836号</a>
                </div>
                <div class="footer-bar" v-else>
                    LTD All rights reserved.&nbsp;
                    <a href="https://beian.miit.gov.cn/">浙ICP备20000836号</a>
                </div>
            </el-main>
        </el-container>
        <!-- <el-footer v-if="LAYOUTMODE.indexOf('footer') >= 0">
                
                </el-footer> -->
        </el-container>
        <router-view v-else />
    </div>
</template>
<script>
import { getCosUrl } from './utils';
export default {
    name: "app",
    data() {
        return {
            LAYOUTMODE: this.$root.layoutMode,
            currentRoute: "",
            menuList: [
                {
                    name: "首页",
                    name_EN: "Homepage",
                    index: "/",
                },
                {
                    name: "产品与服务",
                    name_EN: "About Us",
                    index: "/ProductionAndService",
                },
                {
                    name: "关键成分",
                    name_EN: "Key ingredient",
                    index: "/KeyIngredients",
                },
                {
                    name: "关于华睿",
                    name_EN: "SENCL",
                    index: "/About",
                },
                {
                    name: "研发技术",
                    name_EN: "Technology",
                    index: "/Technology",
                },
                {
                    name: "社会责任",
                    name_EN: "Social Responsibility",
                    index: "/SocialResponsibility",
                },
                {
                    name: "新闻动态",
                    name_EN: "News",
                    index: "/News",
                },
                {
                    name: "联系我们",
                    name_EN: "Contact us",
                    index: "/ContactUs",
                },
            ],
            currentLanguage: "中文",
            languageList: ["中文", "EN"],
            drawerVisible: false,
            hideQrCode: false,
            windowHeight: 0,
            topBtn: {
                bottom: 0,
                right: 0
            },
            footer: ""
        };
    },
    watch: {
        "$route.path": {
            handler(newVal, oldVal) {
                if (newVal != oldVal) {
                    const archor = document.querySelector('#archor');
                    archor.scrollIntoView();
                }
            },
            deep: true,
        },
        "$store.state.device": {
            handler(newVal, oldVal){
                if(newVal != oldVal){
                    this.footer = getCosUrl('/footer.webp');
                }
            },
            deep: true
        },
        "$store.state.language": {
            handler(newVal, oldVal){
                if(newVal != oldVal){
                    this.footer = getCosUrl('/footer.webp');
                }
            },
            deep: true
        }
    },
    mounted() {
        this.handleWindowResize();
        this.footer = getCosUrl('/footer.webp');
    },
    created() {
        window.addEventListener('resize', this.handleWindowResize, true);
        window.addEventListener("scroll", this.handleScroll, true);
    },
    destroyed() {
        window.removeEventListener("resize", this.handleWindowResize);
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleRouteChange(path) {
            this.$router.replace(path);
            this.drawerVisible = false;
        },
        handleIndex() {
            this.$router.replace("/Home");
        },
        handleLanguage() {
            this.$store.commit(
                "updateLanguage",
                this.currentLanguage === "中文" ? "" : "-EN"
            );
            // e.target.blur();
        },
        handleWindowResize(){
            const width = window.innerWidth;
            this.$store.commit('updateDevice', width >= 1200 ? 'web' : 'mobile');
            this.topBtn.bottom = width >= 1200 ? 292 : (100 / 375 * width);
            this.topBtn.right = width >= 1200 ? 314 : (16 / 375 * width);
        },
        handleScroll() {
            const clientHeight =
                document.getElementsByClassName("main-wrapper")[0].scrollHeight;
            const scroll = document.getElementsByClassName("el-main")[0].scrollTop;
            let diff = 0;
            if (clientHeight != 0) {
                diff = clientHeight - scroll;
            }
            this.hideQrCode = diff <= 840 && diff > 100 ? true : false;
        },
    },
};
</script>
<style lang="less">
@import "./less/global_element_ui.less";
@import "./less/global_components.less";
@import "./less/global_common.less";
#app {
    display: flex;
    .main-container {
        justify-content: center;
        flex-direction: row;
        position: relative;
        height: 100%;
    }
    .el-footer {
        background: #000000;
        z-index: 1;
    }
    .el-main {
        flex-direction: column;
        overflow-x: hidden;
        display: flex;
        .footer-bar {
            width: 100%;
            background: #242424;
            color: white;
            font-weight: 350;
            letter-spacing: 0em;
            display: flex;
            align-items: center;
            justify-content: center;
            a {
                text-decoration: none;
                color: white;
            }
        }
        @media screen and (min-device-width: 1200px) {
            .footer-bar {
                height: 2.1875vw;
                line-height: 2.1875vw;
                font-size: 0.625vw;
            }
        }
        @media screen and (max-device-width: 1199px) {
            .footer-bar {
                height: 11.2vw;
                line-height: 11.2vw;
                font-size: 3.2vw;
            }
        }
    }
    .el-backtop {
        background: rgba(255, 255, 255, 0.4);
    }
    .custom-to-top {
        justify-content: center;
        align-items: center;
        display: flex;
        height: 100%;
        width: 100%;
        img.icon {
            position: absolute;
            width: 28.81%;
        }
        img.border {
            height: 100%;
            width: 100%;
        }
    }
    .menu-drawer {
        background: rgba(255, 255, 255, 0.85);
        backdrop-filter: blur(1rem);
        overflow: hidden;
        box-shadow: none;
        .drawer-header {
            align-items: center;
            position: absolute;
            display: flex;
            width: 100%;
            top: 0;
        }
        .menu-wrapper {
            .menu-item {
                transition: all 0.3s ease;
                font-weight: 300;
                cursor: pointer;
                &.active {
                    color: #2874f9;
                    font-weight: 500;
                }
                &:hover {
                    color: #83b1ff;
                    font-weight: 500;
                }
            }
        }
        .el-drawer__body {
            background: rgba(255, 255, 255, 0.1);
            align-items: center;
            display: flex;
        }
        @media screen and (min-device-width: 1200px){
            width: 37.39vw !important;
            .drawer-header{
                padding-left: 9rem;
                height: 6.4rem;
                .el-button {
                    font-size: 2.4rem;
                }
            }
            .menu-wrapper{
                padding-left: 9rem;
                .menu-item{
                    font-size: 2.8rem;
                    margin: 1.6rem 0;

                }
            }
        }
        @media screen and (max-device-width: 1199px) {
            width: 50vw!important;
            .drawer-header{
                justify-content: flex-end;
                padding-right: 6.4vw;
                display: flex;
                .el-button {
                    font-size: 6.4vw;
                }
            }
            .menu-wrapper{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                width: 100%;
                padding: 21.3333vw 0 9.6vw 0;
                .menu-item{
                    font-size: 6.4vw;
                }
                &.EN{
                    .menu-item{
                        font-size: 5.3333vw;
                        width: 35vw;
                    }
                }
            }
        }
    }
    .el-footer {
        @media screen and (min-device-width: 1200px){
            height: 37.4rem !important;
            padding: 0 22rem;
        }
    }
}
</style>
