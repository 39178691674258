import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: "",
    device: ""
  },
  mutations: {
    updateLanguage: (state, language) => {
        state.language = language;
    },
    updateDevice: (state, device) => {
        state.device = device;
    }
  },
  getters: {
    getLanguage: state => {
        return state.language;
    },
    getDevice: state => {
        return state.device;
    }
  },
  actions: {
  },
  modules: {
  }
})
