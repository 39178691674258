import COS from "cos-js-sdk-v5";
import store from "../store";
export function getCosUrl(key){
    const imgFront = 'images-' + store.getters.getDevice + store.getters.getLanguage;
    const cos = new COS({
        //SecretId: "AKIDW0IOGvNdidOvDH9nDOMAoGWbVCgx0obD",
        //SecretKey: "Ur34KM9i4D9tQr680e5KLAmAMOFyVpvq"

        SecretId:"AKIDqeO3ZzMfv471lAZqztYx5ybz83rb6MPN",
        SecretKey:"rSntyvvnY8TZQ2OefU5RXOzMVQ83uZ4M"
    });
    let result = null;
    if(key instanceof Array){
        result = [];
        key.forEach(element => {
            result.push(cos.getObjectUrl({
                Bucket: "sencl-official-1319966376",
                Region: "ap-nanjing",
                Key: imgFront + element
            }))
        });
    } else{
        result = cos.getObjectUrl({
            Bucket: "sencl-official-1319966376",
            Region: "ap-nanjing",
            Key: imgFront + key
        });
    }
    return result;
}

export const handleSwipe = (dom, ref) => {
    const swiper = document.querySelector('#' + dom + ' .el-carousel__container');
    let startPointX = 0;
    let endPointX = 0;

    const resetPoint = () => {
        startPointX = 0;
        endPointX = 0;
    }

    swiper.addEventListener("touchstart", function(e){
        startPointX = e.changeTouches[0].pageX;
    }, false);
    swiper.addEventListener("touchmove", function(e){
        endPointX = e.changeTouches[0].pageX;
    }, false);

    swiper.addEventListener("touchend", function(){
        if(endPointX === 0 || startPointX - endPointX === 0){
            resetPoint();
            return;
        }
        if(startPointX - endPointX > 0){
            resetPoint();
            ref.next();
            return;
        }
        if(startPointX - endPointX < 0){
            resetPoint();
            ref.prev();
            return;
        }
    }, false)
}