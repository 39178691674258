import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        // redirect: '/Home'
        name: 'Home',
        component: () => import('../views/home.vue')
    },
    // {
    //     path: '/Home',
    //     name: 'Home',
        
    // },
    {
        path: '/About',
        name: 'About',
        component: () => import('../views/about.vue')
    },
    {
        path: '/ProductionAndService',
        name: 'ProductionService',
        component: () => import('../views/productionService.vue')
    },
    {
        path: '/KeyIngredients',
        name: 'KeyIngredients',
        component: () => import('../views/keyIngredients.vue')
    },
    {
        path: '/SocialResponsibility',
        name: 'SocialResponsibility',
        component: () => import('../views/socialResponsibility.vue')
    },
    {
        path: '/Technology',
        name: 'Technology',
        component: () => import('../views/technology.vue')
    },
    {
        path: '/MemberDetail',
        name: 'MemberDetail',
        component: () => import('../views/memberDetail.vue')
    },
    {
        path: '/News',
        name: 'News',
        component: () => import('../views/news.vue')
    },
    // {
    //     path: '/Team',
    //     name: 'Team',
    //     component: () => import('../views/team.vue')
    // },
    {
        path: '/ContactUs',
        name: 'ContactUs',
        component: () => import('../views/contactUs.vue')
    },
    // {
    //     path: '/home',
    //     name: 'Home',
    //     component: Home
    // },
    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = new VueRouter({
    mode: 'history',
    // scrollBehavior(){
    //     return {top: 0}
    // },
    routes
});
// eslint-disable-next-line no-unused-vars
router.afterEach((to, from, next) => {
    document.body.scrollTop = 0;
})
// eslint-disable-next-line no-unused-vars
// router.beforeEach((to, from, next) => {    
//     return document.body.scrollTop = 0;
//     // document.documentElement.scrollTop = 0;
//     // window.pageYOffset = next();
// })
export default router
